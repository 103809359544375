<template lang="">
  <Layout>
    <PageHeader :title="title" :items="items"></PageHeader>
    <div class="row col-12" id="article">
      <section class="row col-12 d-flex justify-content-end">
        <div @click="fetchDashboardData(live_event_id)">
          <i
            class="mdi mdi-refresh"
            style="font-size: 30px; cursor: pointer"
          ></i>
        </div>
      </section>
      <template v-if="dashboard_data">
        <div
          class="row col-4"
          v-for="(value, key) in dashboard_data"
          :key="key"
        >
          <div
            class="w-100 card m-3"
            style="box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2)"
          >
            <div class="card-body">
              <div class="d-flex">
                <section class="d-flex flex-column flex-grow-1">
                  <span
                    class="font-weight-bold"
                    style="
                      font-size: 18px;
                      color: black;
                      text-transform: capitalize;
                    "
                    >{{
                      key == "reg_count"
                        ? "Total Registration Count"
                        : key == "visited_during_count"
                        ? "Visited During Count"
                        : "Total Live Count"
                    }}</span
                  >
                  <span class="" style="font-size: 1.25rem">{{ value }}</span>
                </section>
                <i
                  v-if="key === 'live_count'"
                  class="mdi mdi-eye-check"
                  style="font-size: 40px; color: green"
                ></i>
                <i
                  v-if="key === 'reg_count'"
                  class="ri ri-user-follow-line"
                  style="font-size: 40px; color: orange"
                ></i>
                <i
                  v-if="key === 'visited_during_count'"
                  class="mdi mdi-monitor-eye"
                  style="font-size: 40px; color: #0073cf"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import liveEventMixin from "../../../mixins/ModuleJs/live-event";

export default {
  mixins: [MixinRequest, liveEventMixin],
  components: {
    Layout,
    PageHeader,
  },

  data() {
    return {
      title: "  Live Event Dashboard",
      items: [
        {
          text: "Back",
          href: "/live-event",
        },
        {
          text: "List",
        },
      ],
    };
  },
};
</script>
